import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;

    .public-layout-navigation-topbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
    }

    .public-layout-navigation-topbar-mobile {
        display: none;
    }

    .wrapper-content {
        position: relative;
        z-index: 1;
    }

    .public-layout-navigation-footer-mobile {
        display: none;
    }

    .public-layout-navigation-mobile-bottom-menu {
        display: none;
    }

    @media all and (max-width: ${vars.desktopXS}) {
        .public-layout-navigation-topbar {
            display: none;
        }

        .public-layout-navigation-topbar-mobile {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 99;
        }

        .public-layout-navigation-footer {
            display: none;
        }

        .public-layout-navigation-footer-mobile {
            display: block;
        }

        .public-layout-navigation-mobile-bottom-menu {
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 99;
        }
    }
`;
import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: fixed;
    background-color: #FFFFFF;
    padding: 1.5em 0;
    color: #000000;

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo-link {}

        .main {
            padding: 0 1em;
        }

        .login {
            .layout-nav-section {
                .layout-nav-element {
                    margin-right: 1.5em;
                }
            }
        }

        .wcag {
            .wcag-title {
                font-size: .85em;
            }

           .icons {
                display: flex;
                justify-content: center;
                align-items: center;

                .icon-container {
                    display: flex;
                    align-items: flex-start;
                    font-size: 1.2em;
                    font-weight: 700;
                    margin-right: 1em;
                    cursor: pointer;

                    .sign {
                        font-size: 0.6em;
                        padding-top: 0.2em;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
           }
        }
    }

@media(max-width:1300px) {
    .layout-container {
        .main {
            padding: 0;
        }
        .layout-nav-section {
            .button {
                padding: .7em .6em;
            }
        }
    }
}
    .public-layout-modals-job-login-modal {
        .modal-container {
            max-width: 35em;
            flex-direction: column;
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
        .layout-container {
            .logo-link {
                max-width: 13em;
            }
        }
    }
`;
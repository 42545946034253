import { FunctionComponent, useEffect, useState } from 'react';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import { Roles } from 'models/User';

import { withVariables } from 'utils/string';

import logo from 'theme/images/logo.svg';

import { ButtonElements } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import Logo from 'components/layout/Logo';
import NavSection, { Variants } from 'components/layout/NavSection';
import ProfileBox from 'components/layout/ProfileBox';
import Spinner, { Colors, Positions } from 'components/layout/Spinner';
import JobLoginModal from 'components/modules/public/layout/modals/JobLoginModal';

import { Props } from './index';
import StyledComponent from './styles';

export enum LoginTypes {
    Worker = 'loginWorker',
    Company = 'loginCompany',
}

const DynamicModalReactivation = dynamic(
    () => import('components/modules/public/layout/modals/modalReactivation'),
    { loading: () => <Spinner /> }
);

const PublicLayoutNavigationTopbar: FunctionComponent<Props> = ({
    topbarOffer,
    topbarLogin,
    topbarCompany,
    loginWorkerModalProps,
    loginCompanyModalProps,
    onClick,
    state,
    actions,
}) => {
    const { publicRuntimeConfig } = getConfig();
    const { profile, authToken } = state || {};
    const router = useRouter();

    const [isLoginWorkerModalVisible, setIsLoginWorkerModalVisible]: [boolean, Function] = useState(false);
    const [isLoginCompanyModalVisible, setIsLoginCompanyModalVisible]: [boolean, Function] = useState(false);
    const [logged, setLogged]: [boolean, Function] = useState(false);

    const closeModalReactivation = () => actions.logout();
    const reactivation = () => actions.reactivation({}).then(() => actions.profileGet());

    const checkPathname = () => {
        router.pathname === Routes.PublicRegisterCompany && router.push(Routes.PublicHome)
                || router.pathname === Routes.PublicRegisterWorker && router.push(Routes.PublicHome);
    };

    useEffect(() => {
        if (state.profile) {
            if (state.profile.isActive === false) router.push(Routes.PublicActivationAccount);
            else if (logged && state.profile.role === Roles.Company) router.push(publicRuntimeConfig.COMPANY_PANEL_URL + Routes.CompanyAccount);
        }
    }, [state.profile, logged]);
    const companySiteHref = profile?.company?.isPublishedCompanySite ? withVariables(Routes.PublicCompanySite, { slug: profile?.company?.slug })
        : publicRuntimeConfig.COMPANY_PANEL_URL + Routes.CompanySite;
    return (
        <StyledComponent className="public-layout-navigation-topbar">
            <LayoutContainer>
                <Link
                    href="/"
                    prefetch={false}
                >
                    <a className="logo-link">
                        <Logo
                            logo={logo}
                            logoAlt={topbarOffer.logoAlt}
                        />
                    </a>
                </Link>
                <div className="main">
                    <NavSection
                        userEmail={state?.profile?.email}
                        {...topbarOffer}
                    />
                </div>
                <div className="login">
                    {profile
                        ? (
                            <ProfileBox
                                profile={profile}
                                navSectionProps={{
                                    variant: Variants.Dark,
                                    elements: [
                                        {
                                            key: 'workerProfile',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Profil zawodowy',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerProfile),
                                            },
                                        },
                                        {
                                            key: 'jobApplications',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Wysłane zgłoszenia',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerJobApplications),
                                            },
                                        },
                                        {
                                            key: 'documents',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Moje dokumenty',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerMyDocuments),
                                            },
                                        },
                                        {
                                            key: 'jobFavourites',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Zapisane oferty',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerJobFavourites),
                                            },
                                        },
                                        {
                                            key: 'jobSearchQueries',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Zapisane wyszukiwania',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerJobSearchQueries),
                                            },
                                        },
                                        {
                                            key: 'configWorker',
                                            visible: Boolean(profile?.role === Roles.Worker),
                                            buttonProps: {
                                                children: 'Ustawienia',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.WORKER_PANEL_URL + withVariables(Routes.WorkerConfig),
                                            },
                                        },
                                        {
                                            key: 'jobs',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Ogłoszenia',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanyJobs),
                                            },
                                        },
                                        {
                                            key: 'companyProfile',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Mój profil',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanyMe),
                                            },
                                        },
                                        {
                                            key: 'companySite',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Profil firmy',
                                                element: ButtonElements.Anchor,
                                                href: companySiteHref,
                                            },
                                        },
                                        {
                                            key: 'plans',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Pakiety i płatności',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanyPlans),
                                            },
                                        },
                                        {
                                            key: 'integrations',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Integracje z ATS',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanyIntegrations),
                                            },
                                        },
                                        {
                                            key: 'configCompany',
                                            visible: Boolean(profile?.role === Roles.Company),
                                            buttonProps: {
                                                children: 'Ustawienia',
                                                element: ButtonElements.Anchor,
                                                href: publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanyConfig),
                                            },
                                        },
                                        {
                                            key: 'logout',
                                            visible: true,
                                            buttonProps: {
                                                children: 'Wyloguj',
                                                onClick: () => {
                                                    actions.logout();
                                                    router.push(Routes.PublicHome);
                                                },
                                            },
                                        },
                                    ].filter(element => element.visible),
                                }}
                            />
                        )
                        : authToken
                            ? (
                                <Spinner
                                    color={Colors.Green}
                                    position={Positions.Relative}
                                />
                            ) : (
                                <NavSection
                                    onCloseNav={(nextElementKey: string) => {
                                        if(nextElementKey === LoginTypes.Worker) {
                                            setIsLoginWorkerModalVisible(!isLoginWorkerModalVisible);
                                        }
                                        if(nextElementKey === LoginTypes.Company) {
                                            setIsLoginCompanyModalVisible(!isLoginCompanyModalVisible);
                                        }
                                    }}
                                    {...topbarLogin}
                                />
                            )
                    }
                </div>
                <div className="company">
                    <NavSection {...topbarCompany} />
                </div>
                <div className="wcag">
                    <div
                        className="wcag-title"
                        dangerouslySetInnerHTML={{ __html: 'Powiększ litery:' }}
                    />
                    <div className="icons">
                        <div
                            className="icon-container"
                            onClick={() => onClick(true, false)}
                        >
                            A
                            <span className="sign">+</span>
                        </div>
                        <div
                            className="icon-container"
                            onClick={() =>  onClick(false, true)}
                        >
                            A
                            <span className="sign">-</span>
                        </div>
                    </div>
                </div>
            </LayoutContainer>

            {isLoginWorkerModalVisible && (
                <JobLoginModal
                    {...loginWorkerModalProps}
                    modalProps={{
                        ...loginWorkerModalProps?.modalProps,
                        onClose: () => {
                            setIsLoginWorkerModalVisible(false);
                        },
                    }}
                    roleType={Roles.Worker}
                    onSuccess={() => {
                        setIsLoginWorkerModalVisible(false);
                        checkPathname();
                    }}
                />
            )}

            {isLoginCompanyModalVisible && (
                <JobLoginModal
                    {...loginCompanyModalProps}
                    modalProps={{
                        ...loginCompanyModalProps?.modalProps,
                        onClose: () => {
                            setIsLoginCompanyModalVisible(false);
                        },
                    }}
                    roleType={Roles.Company}
                    onSuccess={() =>{
                        setLogged(true);
                        setIsLoginCompanyModalVisible(false);
                        checkPathname();
                    }}
                />
            )}
            { profile?.deletedFrom && (
                <DynamicModalReactivation
                    closeModal={closeModalReactivation}
                    reactivation={reactivation}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutNavigationTopbar;
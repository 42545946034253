import { Props as ButtonProps } from 'components/layout/Button';
import { Props as NavSectionProps } from 'components/layout/NavSection';

import Component from './component';

export interface Props {
    footerLogo?: {
        alt?: string;
    };
    expandArrow?: {
        open?: string;
        close?: string;
    };
    footerWorker: NavSectionProps;
    footerLead: NavSectionProps;
    footerContact: NavSectionProps;
    footerBottom: NavSectionProps;
    copyright: ButtonProps;
    footerSocials: {
        key: string;
        icon: string;
        alt?:string;
        link: string;
        width?: number;
        height?: number;
    }[];
}

Component.defaultProps = {};

export default Component;
import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    background-color: #000000;
    padding: 3em 0 7em 0;

    .top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: .1em solid #FFFFFF;
        padding: 2em 1.8em;

        .top-logo {
        }

        .top-contact {
            padding: 2.4em 0;

            .layout-nav-section {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.1em;

                > * {
                    margin-right: 0;
                    padding-top: 1em;

                    &:first-of-type {
                        padding-top: 0;
                    }
                }
            }
        }

        .top-socials {
            display: flex;
            justify-content: flex-start;
            align-self: flex-start;

            > * {
                padding-right: 1em;

                .icon {}
            }
        }
    }

    .main {
        .main-col {
            border-bottom: .1em solid #FFFFFF;
            padding: 2em 1.8em;

            .col-headline {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.1em;
                color: #FFFFFF;
                font-weight: 700;
            }

            .layout-nav-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.1em;

                > * {
                    padding-top: 1em;
                }
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    .bottom {
        padding: 2em 1.8em;
        border-top: .1em solid #FFFFFF;

        .col {
            .layout-nav-section {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.1em;

                margin-right: 0;

                > * {
                    padding-top: 1em;
                }
            }
        }
    }
    .copyright {
        padding: 2em 1.8em;
        border-top: .1em solid #FFFFFF;
        .copyright-link {
            color: #FFFFFF;
            font-size: .9em;
            .link {
                color: #FFFFFF;
                text-decoration: underline;
            }


        }
    }
    @media(max-width:${variables.tabletS}) {
        .bottom {

        .col {

                .layout-nav-section {
                    .layout-nav-element {
                        &:first-child {
                            display: none;
                        }
                    }
                }

        }
    }
    }
`;
import { FunctionComponent, useContext, useState } from 'react';
import dayjs from 'dayjs';

import LocaleContext from 'components/context/Locale';
import ServiceWorkModal from 'components/modules/public/layout/modals/ServiceWorkModal/component';
import Footer from 'components/modules/public/layout/navigation/Footer';
import FooterMobile from 'components/modules/public/layout/navigation/FooterMobile';
import MobileBottomMenu from 'components/modules/public/layout/navigation/MobileBottomMenu';
import Topbar from 'components/modules/public/layout/navigation/Topbar';
import TopbarMobile from 'components/modules/public/layout/navigation/TopbarMobile';

import { Props } from './index';
import StyledComponent from './styles';

export enum WcagValues {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

const LayoutDefault: FunctionComponent<Props> = ({ children }) => {
    const localeContext = useContext(LocaleContext);
    const locale = localeContext?.translations;
    const today = dayjs(new Date());
    const modalEndDate = dayjs('2023-12-13 13:30');
    const isBeforeEndDate = today.isBefore(modalEndDate);
    const [wcagValue, setWcagValue]: [string, Function] = useState(WcagValues.Small);

    const getWcagValue = (more?: boolean, less?: boolean) => {
        if(more) {
            switch (wcagValue) {
                case WcagValues.Small:
                    setWcagValue(WcagValues.Medium);
                    break;
                case WcagValues.Medium:
                    setWcagValue(WcagValues.Large);
                    break;
            }
        }
        if(less) {
            switch (wcagValue) {
                case WcagValues.Large:
                    setWcagValue(WcagValues.Medium);
                    break;
                case WcagValues.Medium:
                    setWcagValue(WcagValues.Small);
                    break;
            }
        }
    };

    const getWcagValueMobile = () => {
        switch (wcagValue) {
            case WcagValues.Small:
                setWcagValue(WcagValues.Medium);
                break;
            case WcagValues.Medium:
                setWcagValue(WcagValues.Large);
                break;
            case WcagValues.Large:
                setWcagValue(WcagValues.Small);
                break;
        }
    };

    const getWcagStyle = () => {
        if(wcagValue === WcagValues.Small) return '100%';
        if(wcagValue === WcagValues.Medium) return '110%';
        if(wcagValue === WcagValues.Large) return '120%';
    };

    return (
        <StyledComponent
            className="layout-default"
            style={{ fontSize: getWcagStyle() }}
        >
            <Topbar
                topbarOffer={locale.navigation.topbarOffer}
                topbarLogin={locale.navigation.topbarLogin}
                topbarCompany={locale.navigation.topbarCompany}
                loginWorkerModalProps={locale.navigation.loginWorkerModalProps}
                loginCompanyModalProps={locale.navigation.loginCompanyModalProps}
                onClick={getWcagValue}
            />
            <TopbarMobile
                topbarMobile={locale.navigation.topbarMobile}
                onClick={getWcagValueMobile}
            />
            <div className="wrapper-content">
                {children}
            </div>
            {isBeforeEndDate && (
                <ServiceWorkModal />

            )}
            <MobileBottomMenu mobileBottomMenu={locale.navigation.mobileBottomMenu} />
            <Footer
                footerLogo={locale.navigation.footerLogo}
                footerWorker={locale.navigation.footerWorker}
                footerLead={locale.navigation.footerLead}
                footerContact={locale.navigation.footerContact}
                footerSocials={locale.navigation.footerSocials}
                footerBottom={locale.navigation.footerBottom}
            />
            <FooterMobile
                expandArrow={locale.navigation.expandArrow}
                footerLogo={locale.navigation.footerLogo}
                footerWorker={locale.navigation.footerWorker}
                footerLead={locale.navigation.footerLead}
                footerContact={locale.navigation.footerContact}
                footerSocials={locale.navigation.footerSocials}
                footerBottom={locale.navigation.footerBottom}
                copyright={locale.navigation.copyright}
            />
        </StyledComponent>
    );
};

export default LayoutDefault;
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import User from 'models/User';

import { logout, profileGet, reactivation } from 'store/modules/user/actions';
import { State as ReduxState } from 'store/state';

import { Props as ButtonProps } from 'components/layout/Button';
import { Props as ButtonGroupProps } from 'components/layout/ButtonGroup';
import { Props as NavSectionProps } from 'components/layout/NavSection';
import { Props as LoginModalProps } from 'components/modules/public/layout/modals/JobLoginModal';

import Component from './component';

export enum MainActiveKeys {
    Employee = 'employee',
    Employer = 'employer',
}

export interface Props extends ParentProps {
    state: {
        authToken: string;
        profile: User;
    };
    actions: {
        logout: () => Promise<any>;
        reactivation: (params: object) => Promise<any>;
        profileGet: Function;
    };
}

export interface ParentProps {
    topbarMobile: {
        logo: {
            alt:string;
        };
        header: ButtonGroupProps;
        wcag: {
            alt:string;
        };
        hamburger: {
            open: string;
            close: string;
        };
        main: {
            employee: NavSectionProps;
            employeeLogged?: NavSectionProps;
            employer: NavSectionProps;
            employerLogged?: NavSectionProps;
        };
        buttonProps: {
            login: ButtonProps;
            register: ButtonProps;
            registerCompany: ButtonProps;
            logout: ButtonProps;
        };
        buttonTitles: {
            employee: string;
            employer: string;
        };
        loginModalProps: LoginModalProps;
        loginModalPropsCompany?: LoginModalProps;
    };
    onClick?: (more?: boolean, less?: boolean) => any;
}

const mapStateToProps = (state: ReduxState, ownProps?: Props): object => {
    return {
        state: {
            profile: state.user.profile,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            logout: bindActionCreators(logout, dispatch),
            reactivation: bindActionCreators(reactivation, dispatch),
            profileGet: bindActionCreators(profileGet, dispatch),
        },
    };
};

Component.defaultProps = {
    onClick: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
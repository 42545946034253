import { FunctionComponent, useEffect, useState } from 'react';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Routes } from 'types/routes';

import { Roles } from 'models/User';

import { withVariables } from 'utils/string';

import menuHamburger from 'theme/icons/menu-hamburger.svg';
import wcag from 'theme/icons/wcag.svg';
import close from 'theme/icons/x-black.svg';
import AvatarPlaceholder from 'theme/images/avatar-placeholder.svg';
import logo from 'theme/images/logo.svg';

import Button from 'components/layout/Button';
import ButtonGroup from 'components/layout/ButtonGroup';
import LayoutContainer from 'components/layout/LayoutContainer';
import Logo from 'components/layout/Logo';
import NavSection from 'components/layout/NavSection';
import Spinner from 'components/layout/Spinner';

import { MainActiveKeys, Props } from './index';
import StyledComponent from './styles';

const DynamicJobLoginModal = dynamic(
    () => import('components/modules/public/layout/modals/JobLoginModal'),
    { loading: () => <Spinner /> }
);

const DynamicModalReactivation = dynamic(
    () => import('components/modules/public/layout/modals/modalReactivation'),
    { loading: () => <Spinner /> }
);


const PublicLayoutNavigationTopbarMobile: FunctionComponent<Props> = ({ topbarMobile, onClick, state, actions }) => {
    const [isExpanded, setIsExpanded]: [boolean, Function] = useState(false);
    const [buttonGroupActiveKey, setButtonGroupActiveKey]: [string, Function] = useState(MainActiveKeys.Employee);
    const [isLoginModalVisible, setIsLoginModalVisible]: [boolean, Function] = useState(false);
    const [logged, setLogged]: [boolean, Function] = useState(false);

    const { publicRuntimeConfig } = getConfig();
    const router = useRouter();
    const { profile } = state;

    const closeModalReactivation = () => actions.logout();
    const reactivation = () => actions.reactivation({}).then(() => actions.profileGet());

    const checkPathname = () => {
        router.pathname === Routes.PublicRegisterCompany && router.push(Routes.PublicHome)
            || router.pathname === Routes.PublicRegisterWorker && router.push(Routes.PublicHome);
    };

    useEffect(() => {
        if (state.profile) {
            if (state.profile.isActive === false) router.push(Routes.PublicActivationAccount);
            else if (logged && state.profile.role === Roles.Company) router.push(publicRuntimeConfig.COMPANY_PANEL_URL + Routes.CompanyAccount);
        }
    }, [state.profile, logged]);
    const companySiteHref = profile?.company?.isPublishedCompanySite ? withVariables(Routes.PublicCompanySite, { slug: profile?.company?.slug })
        : publicRuntimeConfig.COMPANY_PANEL_URL + withVariables(Routes.CompanySite);
    return (
        <StyledComponent className={classnames('public-layout-navigation-topbar-mobile', {
            'company-logged' : state?.profile && state?.profile?.role === Roles.Company,
        })}
        >
            <div className="main">
                <LayoutContainer>
                    <div className="col col-left">
                        <Link
                            href="/"
                            prefetch={false}
                        >
                            <a className="logo-link">
                                <Logo
                                    logo={logo}
                                    logoAlt={topbarMobile?.logo?.alt}
                                />
                            </a>
                        </Link>
                        <img
                            className={classnames(
                                'wcag-icon',
                                {
                                    active: isExpanded,
                                }
                            )}
                            src={wcag}
                            onClick={() => onClick()}
                            alt={topbarMobile?.wcag?.alt}
                            loading="lazy"
                        />
                    </div>
                    <div className="col col-right">
                        <img
                            className="hamburger-icon"
                            src={isExpanded ? close : menuHamburger}
                            onClick={() => setIsExpanded(!isExpanded)}
                            alt={isExpanded ? topbarMobile.hamburger.close : topbarMobile.hamburger.open}
                            loading="lazy"
                        />
                    </div>
                </LayoutContainer>
            </div>
            <div
                className={classnames(
                    'submenu',
                    {
                        active: isExpanded,
                    }
                )}
            >
                <div className="menu-container">
                    <div className="top">
                        <LayoutContainer>
                            <div className="header">
                                {profile ? (
                                    <div className={classnames('user-info-wrapper', {
                                        'is-company': Boolean(state.profile.role === Roles.Company),
                                    })}
                                    >
                                        <img
                                            className="user-avatar"
                                            src={profile?.avatar?.imageUrl || AvatarPlaceholder}
                                            alt="user avatar"
                                            loading="lazy"
                                        />
                                        <div className="user-data">
                                            <b className="user-name">{profile?.shortName}</b>
                                            <p>{profile?.email || profile?.phone}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <ButtonGroup
                                        activeKey={buttonGroupActiveKey}
                                        onSetActiveKey={nextActiveKey => setButtonGroupActiveKey(nextActiveKey)}
                                        buttons={topbarMobile.header.buttons}
                                    />
                                )}
                            </div>
                            <div className="main-section">
                                {profile ? (
                                    <>
                                        {profile?.role === Roles.Worker
                                            ? topbarMobile.main.employeeLogged.elements.map(element => (
                                                <Button
                                                    className="button-logged-user"
                                                    key={element.key}
                                                    {...element.buttonProps}
                                                    href={publicRuntimeConfig.WORKER_PANEL_URL + Routes[element.buttonProps.href]}
                                                />
                                            ))
                                            : topbarMobile.main.employerLogged.elements.map(element => (
                                                element.key === 'phone-call'
                                                    ? (
                                                        <a
                                                            className="phone-call-link"
                                                            href={element.buttonProps.href}
                                                        >
                                                            <Button
                                                                className="button-logged-user phone-link"
                                                                key={element.key}
                                                                {...element.buttonProps}
                                                                href=""
                                                            />
                                                        </a>
                                                    )
                                                    : (
                                                        element.key === 'company-site'
                                                            ? (
                                                                <Button
                                                                    className="button-logged-user"
                                                                    key={element.key}
                                                                    {...element.buttonProps}
                                                                    href={companySiteHref}
                                                                />
                                                            )
                                                            : (
                                                                <Button
                                                                    className="button-logged-user"
                                                                    key={element.key}
                                                                    {...element.buttonProps}
                                                                    href={publicRuntimeConfig.COMPANY_PANEL_URL + Routes[element.buttonProps.href]}
                                                                />
                                                            )
                                                    )
                                            ))
                                        }
                                    </>
                                ) : (
                                    <>
                                        {buttonGroupActiveKey === MainActiveKeys.Employee && (
                                            <NavSection {...topbarMobile.main.employee} />
                                        )}
                                        {buttonGroupActiveKey === MainActiveKeys.Employer && (
                                            <NavSection {...topbarMobile.main.employer} />
                                        )}
                                    </>
                                )}
                            </div>
                        </LayoutContainer>
                    </div>
                    {profile ? (
                        <div className="bottom">
                            <Button
                                className="logout-button"
                                onClick={() => actions.logout()}
                                {...topbarMobile.buttonProps.logout}
                            />
                        </div>
                    ) : (
                        <div className="bottom">
                            {buttonGroupActiveKey === MainActiveKeys.Employee
                                ? (<p className='button-title'>{topbarMobile?.buttonTitles.employee}</p>)
                                : (<p className='button-title'>{topbarMobile?.buttonTitles.employer}</p>)
                            }
                            <Button
                                onClick={() => setIsLoginModalVisible(true)}
                                {...topbarMobile.buttonProps.login}
                            />
                            {buttonGroupActiveKey === MainActiveKeys.Employee
                                ? (<Button {...topbarMobile.buttonProps.register} />)
                                : (<Button {...topbarMobile.buttonProps.registerCompany} />)
                            }
                        </div>
                    )
                    }
                </div>
            </div>

            {isLoginModalVisible ? buttonGroupActiveKey === MainActiveKeys.Employee
                ? (
                    <DynamicJobLoginModal
                        {...topbarMobile?.loginModalProps}
                        modalProps={{
                            ...topbarMobile?.loginModalProps?.modalProps,
                            onClose: () => {
                                setIsLoginModalVisible(false);
                            },
                        }}
                        onSuccess={() => {
                            setIsLoginModalVisible(false);
                            checkPathname();
                        }}
                        roleType={Roles.Worker}
                    />
                ) : (
                    <DynamicJobLoginModal
                        {...topbarMobile?.loginModalPropsCompany}
                        modalProps={{
                            ...topbarMobile?.loginModalPropsCompany?.modalProps,
                            onClose: () => {
                                setIsLoginModalVisible(false);
                            },
                        }}
                        onSuccess={() => {
                            setIsLoginModalVisible(false);
                            setLogged(true);
                            checkPathname();
                        }}
                        roleType={Roles.Company}
                    />
                ) : null
            }

            {profile?.deletedFrom && (
                <DynamicModalReactivation
                    closeModal={closeModalReactivation}
                    reactivation={reactivation}
                />
            )}
        </StyledComponent>
    );
};

export default PublicLayoutNavigationTopbarMobile;
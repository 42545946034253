import { FunctionComponent, useState } from 'react';

import arrowExpandBottom from 'theme/icons/arrow-expand-bottom.svg';
import arrowExpandTop from 'theme/icons/arrow-expand-top.svg';
import logoWhite from 'theme/images/logo-white.svg';

import LayoutContainer from 'components/layout/LayoutContainer';
import NavSection from 'components/layout/NavSection';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutNavigationFooterMobile: FunctionComponent<Props> = ({ footerWorker, footerLead, footerContact, footerSocials, footerBottom, footerLogo, expandArrow, copyright }) => {
    const [isWorkerMenuExpanded, setIsWorkerMenuExpanded]: [boolean, Function] = useState(false);
    const [isLeadMenuExpanded, setIsLeadMenuExpanded]: [boolean, Function] = useState(false);

    return (
        <StyledComponent className="public-layout-navigation-footer-mobile">
            <div className="top">
                <LayoutContainer>
                    <img
                        className="top-logo"
                        src={logoWhite}
                        loading="lazy"
                        alt={footerLogo?.alt}
                    />
                    <div className="top-contact">
                        <NavSection {...footerContact} />
                    </div>
                    <div className="top-socials">
                        {Array.isArray(footerSocials) && footerSocials.length > 0 && footerSocials.map(element => (
                            <a
                                className="socials-icon"
                                key={element?.key}
                                href={element?.link}
                            >
                                <img
                                    className="icon"
                                    src={element?.icon}
                                    loading="lazy"
                                    alt={element.alt}
                                    width={element?.width}
                                    height={element?.height}
                                />
                            </a>
                        ))}
                    </div>
                </LayoutContainer>
            </div>
            <div className="main">
                <div className="main-col">
                    <LayoutContainer>
                        {footerWorker?.label && (
                            <div
                                className="col-headline"
                                onClick={() => setIsWorkerMenuExpanded(!isWorkerMenuExpanded)}
                            >
                                <span
                                    className="headline"
                                    dangerouslySetInnerHTML={{ __html: footerWorker.label }}
                                />
                                <img
                                    className="icon"
                                    src={isWorkerMenuExpanded ? arrowExpandTop : arrowExpandBottom}
                                    loading="lazy"
                                    alt={isWorkerMenuExpanded ? expandArrow.close : expandArrow.open}
                                />
                            </div>
                        )}
                        {isWorkerMenuExpanded && (
                            <NavSection {...footerWorker} />
                        )}
                    </LayoutContainer>
                </div>
                <div className="main-col">
                    <LayoutContainer>
                        {footerLead?.label && (
                            <div
                                className="col-headline"
                                onClick={() => setIsLeadMenuExpanded(!isLeadMenuExpanded)}
                            >
                                <span
                                    className="headline"
                                    dangerouslySetInnerHTML={{ __html: footerLead.label }}
                                />
                                <img
                                    className="icon"
                                    src={isLeadMenuExpanded ? arrowExpandTop : arrowExpandBottom}
                                    loading="lazy"
                                    alt={isLeadMenuExpanded ? expandArrow.close : expandArrow.open}
                                />
                            </div>
                        )}
                        {isLeadMenuExpanded && (
                            <NavSection {...footerLead} />
                        )}
                    </LayoutContainer>
                </div>
            </div>
            <div className="bottom">
                <LayoutContainer>
                    <div className="col">
                        <NavSection {...footerBottom} />
                    </div>
                </LayoutContainer>
            </div>
            <div className="copyright">
                <LayoutContainer>
                    <div className="layout-nav-section">
                        <p
                            className='copyright-link'
                            dangerouslySetInnerHTML={{ __html:copyright?.buttonProps?.children }}
                        />
                    </div>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationFooterMobile;
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import User from 'models/User';

import { logout, profileGet, reactivation } from 'store/modules/user/actions';
import { State as ReduxState } from 'store/state';

import { Props as NavSectionProps } from 'components/layout/NavSection';
import { Props as LoginModalProps } from 'components/modules/public/layout/modals/JobLoginModal';

import Component from './component';

export interface Props extends ParentProps {
    state: {
        authToken: string;
        profile: User;
    };
    actions: {
        logout: () => Promise<any>;
        reactivation: (params: object) => Promise<any>;
        profileGet: Function;
    };
}
export interface ParentProps {
    topbarOffer: NavSectionProps;
    topbarLogin: NavSectionProps;
    topbarCompany: NavSectionProps;
    loginWorkerModalProps: LoginModalProps;
    loginCompanyModalProps: LoginModalProps;
    onClick?: (more?: boolean, small?: boolean) => any;
}

const mapStateToProps = (state: ReduxState, ownProps?: Props): object => {
    return {
        state: {
            authToken: state.user.authToken,
            profile: state.user.profile,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            logout: bindActionCreators(logout, dispatch),
            reactivation: bindActionCreators(reactivation, dispatch),
            profileGet: bindActionCreators(profileGet, dispatch),
        },
    };
};

Component.defaultProps = {
    onClick: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
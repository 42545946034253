import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	background-color: #ffffff;
	padding: 1.5em 0;
	color: #000000;
	overflow: hidden;
	z-index: 99;

	.main {
		z-index: 99;
		background-color: #ffffff;

		.layout-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 3em;

			.col {
				.layout-logo {
					margin-right: 2em;

					.logo-img {
						max-width: 13em;
					}
				}

				&.col-left {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			.wcag-icon {
				opacity: 0;
				max-width: 13em;
				transition: opacity 0.3s ease-in-out;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.submenu {
		background-color: #ffffff;
		max-height: 0;
		transition: max-height 0.3s ease-in-out;

		.menu-container {
			padding: 1.2em 0 4em 0;
			opacity: 0;
			transition: opacity 0.3s ease-in-out;

			.top {
				.layout-container {
					width: 100%;
				}

				.header {
					display: flex;
					justify-content: center;
					align-items: center;

					.user-info-wrapper {
						overflow: hidden;
						max-height: 10em;
						width: 100%;
						background-color: ${vars.colorBackground};
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 0 2em 0;

						.user-avatar {
							background-color: ${vars.colorBackgroundContrast};
							border: 0.08em solid ${vars.colorBackgroundBorder};
							max-width: 6em;
							max-height: 6em;
							height: 5em;
							width: 5em;
							border-radius: 100%;
							margin: 1em;
						}

						&.is-company {
							background-color: ${vars.colorBlack};

							.user-data {
								color: ${vars.colorTextLighter};
							}
							.user-name {
								color: ${vars.colorElement};
							}
						}
					}

					.layout-button-group {
						border: none;
						margin: 0 2em;
					}
				}

				.main-section {
					.phone-call-link {
						text-decoration: none;
						color: #ffffff;
					}

					.button-logged-user {
						margin-bottom: 0;
						margin: 1em 0;

						.button-content {
							color: #000000;

							.button-call {
								display: flex;
								text-align: center;
								align-items: center;
								gap: 0.3em;
								font-size: 1em;
								color: #ffffff;
							}
						}

						&.variant-primary {
							.button {
								font-size: 1em;
								padding: 1em 3em;
							}
						}

						&.variant-dark {
							.button {
								background-color: transparent;
							}
						}

						a {
							color: #000000;
						}

						&.phone-link {
							.button-content {
								color: #ffffff;
							}
						}
					}

					.layout-nav-section {
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 2.5em 0;
						font-size: 1.55em;

						> * {
							margin-right: 0;
							padding-bottom: 1em;

							&:last-of-type {
								padding-bottom: 0;
							}
						}
					}
				}
			}

			.bottom {
				padding: 0em 7.6em;

				.logout-button {
					.button {
						background-color: transparent;
					}

					.button-content {
						color: #000000;
					}
				}

				> * {
					display: block;

					.button {
						width: 100%;
						padding: 1.1em 0;
					}

					&:last-of-type {
						padding-top: 1.5em;
					}
				}
				.button-title {
					font-size: 1.4em;
					font-weight: 700;
					text-align: center;
					margin-bottom: 1em;
				}
			}
		}

		&.active {
			max-height: 100vh;

			.menu-container {
				opacity: 1;
				background-color: #ffffff;

				.bottom {
					&::after {
						content: '';
						display: block;
						position: fixed;
						bottom: 0;
						left: 0;
						height: 80%;
						width: 100vw;
						background-color: #000000;
						opacity: 40%;
						z-index: -1;
					}
				}
			}

			.backdrop {
				display: block;
			}
		}
	}

	.backdrop {
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgb(1 67 93 / 50%);
		z-index: 98;

		&.active {
			opacity: 1;
		}
	}
	@media (max-height: 799px) {
		&.company-logged {
			z-index: 100;
		overflow-y:scroll;
			.submenu {
					&.active {
						height: 100vh;
						.menu-container {
							height: 100%;
							overflow: scroll;
						}
					}
			}

		}
	}
	@media (max-width: ${vars.mobileS}) {
		.submenu {
			.menu-container {
				padding: 2em 0;
				.top {
					.main-section {
						.layout-nav-section {
							padding: 1.8em 0;
						}
					}
				}
				.bottom {
					padding: 0em 6em;
				}
			}
		}
	}
`;

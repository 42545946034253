import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    background-color: #000000;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 3em 0;

        .footer-logo {
            align-self: center;
            padding-right: 5em;
        }

        .nav-headline {
            color: white;
            font-weight: 700;
            padding-bottom: 1.7em;
            font-size:  0.8333333333333334em;
        }

        .col {
            &.col-nav {
                .layout-nav-section {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    > * {
                        margin-right: 0;
                        padding-top: 1em;

                        &:first-of-type {
                            padding-top: 0;
                        }
                    }
                }
                .nav-noheadline {
                    padding-top: 2.55em;
                }
            }
        }

        .footer-socials {
            display: flex;
            justify-content: space-between;
            align-self: flex-end;

            > * {
                padding-right: 1em;
            }
        }
    }

    .bottom {
        padding: 1.5em 0;
        border-top: .1em solid #FFFFFF;

        .col {
            &.col-nav {
                .layout-nav-section {
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-right: 0;
                    .layout-nav-element {
                        &:first-child {
                            margin-right: auto;
                            .layout-button {
                                &:hover {
                                    .button-content {
                                        box-shadow: none;
                                    }
                                }
                                .button-content {
                                    .link {
                                        color: inherit;
                                        text-decoration: underline;

                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }

`;
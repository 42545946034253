import { FunctionComponent } from 'react';

import logoWhite from 'theme/images/logo-white.svg';

import LayoutContainer from 'components/layout/LayoutContainer';
import NavSection from 'components/layout/NavSection';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutNavigationFooter: FunctionComponent<Props> = ({ footerWorker, footerLead, footerContact, footerSocials, footerBottom, footerLogo }) => {
    return (
        <StyledComponent className="public-layout-navigation-footer">
            <LayoutContainer>
                <div className="top">
                    <img
                        className="footer-logo"
                        src={logoWhite}
                        loading="lazy"
                        alt={footerLogo?.alt}
                    />
                    <div className="col col-nav">
                        {footerWorker?.label && (
                            <div
                                className="nav-headline"
                                dangerouslySetInnerHTML={{ __html: footerWorker.label }}
                            />
                        )}
                        <NavSection {...footerWorker} />
                    </div>
                    <div className="col col-nav">
                        {footerLead?.label && (
                            <div
                                className="nav-headline"
                                dangerouslySetInnerHTML={{ __html: footerLead.label }}
                            />
                        )}
                        <NavSection {...footerLead} />
                    </div>
                    <div className="col col-nav">

                        <div className="nav-noheadline">
                            <NavSection {...footerContact} />
                        </div>
                    </div>
                    <div className="footer-socials">
                        {Array.isArray(footerSocials) && footerSocials.length > 0 && footerSocials.map(element => (
                            <a
                                className="socials-icon"
                                key={element?.key}
                                href={element?.link}
                            >
                                <img
                                    className="icon"
                                    src={element?.icon}
                                    loading="lazy"
                                    alt={element?.alt}
                                    width={element?.width}
                                    height={element?.height}
                                />
                            </a>
                        ))}
                    </div>
                </div>
            </LayoutContainer>
            <div className="bottom">
                <LayoutContainer>
                    <div className="col col-nav">
                        <NavSection {...footerBottom} />
                    </div>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutNavigationFooter;